import React from 'react';
import './styles.css';
import ScrollAnimation from 'react-animate-on-scroll';

import ReactMarkdown from 'react-markdown';


const FAQSection = ({markdown}) => {
    return (
        <>
            <ScrollAnimation animateIn="slideInUp" animateOnce>
                <div className='services-circle-top'/>
                <img src="/img/banner/page-sep-1.png" style={{position: "relative"}}></img>
            </ScrollAnimation>
            <section className="section-space-default-less30 bg-light-accent100">
                
                <div className="container">
                    <h2 className='fs-section-header'>Frequently Asked Questions</h2>
                    <br/>
                    {/* Content */}
                    <ReactMarkdown>{markdown}</ReactMarkdown>
                </div>
            </section>
        </>
    )
}

export default FAQSection