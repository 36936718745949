import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "components/layout";
import ReactMarkdown from "react-markdown";
import InnerPageBanner from "components/inner_page_banner";
import FAQSection from "components/faqs_section";
import CTABar1 from "components/cta_bar_1";
import "css/services.css";
import LargeHeroBanner from "components/large_hero_banner";
import FsAnalytics from "components/fs_analytics";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  console.log(data);
  const { markdownRemark, allMarkdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      {/* Finstead Analytics */}
      <FsAnalytics />

      <div id="header-area-space" className="slider-area bg-light-primary">
        <LargeHeroBanner bgImage={`/${frontmatter.feature_image}`}>
          <h1>
            <strong>{frontmatter.title}</strong>
          </h1>
          <br />
          <h2>{frontmatter.description}</h2>
          <br />
          <a
            href="/contact"
            className="btn-fill color-light radius-4 btn-icon size-sm icon-right mt-2"
          >
            Enquire Now
            <i className="fas fa-angle-right" />
          </a>
        </LargeHeroBanner>
      </div>

      <CTABar1 />

      {/* Content Area */}
      <section
        className="section-space-less30"
        style={{ marginBottom: "0px", paddingBottom: "0px" }}
      >
        <div className="container">
          <div className="service-details margin-b-30r">
            <h2 style={{ fontWeight: "200" }}>{frontmatter.title}</h2>
            <br />
            {/* Post Content */}
            <div style={{ fontSize: "19px" }}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </section>

      {/* Content Blocks */}
      <div className="container mb-5 content-container">
        <div className="row">
          {frontmatter.content_blocks &&
            frontmatter.content_blocks.map((content_block, i) => (
              <div className="col-xs-12 col-sm-12 col-md-6 mb-5">
                <ReactMarkdown>{content_block.content_block}</ReactMarkdown>
                <br />
                <div
                  style={{ width: "80px", borderBottom: "3px solid #424242" }}
                />
                <br />
              </div>
            ))}
        </div>
      </div>
      <br />
      <br />
      <br />

      <div className="content-container">
        <FAQSection markdown={frontmatter.faqs} />
      </div>
    </Layout>
  );
}
export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        feature_image
        description
        faqs
        content_blocks {
          content_block
        }
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      limit: 6
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            title
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
